import React, { useState, useEffect } from 'react';
import '../styles/_homedeveloper.scss';
import { usePrivy } from '@privy-io/react-auth';
import LoggedIn from '../components/LoggedIn';

  const HomeDeveloper = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { user, login, logout } = usePrivy();

  useEffect(() => {
    if (user) {
      setIsLoggedIn(true);
    }
  }, [user]);

  const openWebsite = (url) => {
    window.open(url, '_blank');
  };

  const handleLogout = async () => {
    await logout();
    setIsLoggedIn(false); 
  };

console.log(user);
  return (
    <div className="developer-wrapper">
        <div className="content-wrapper">
      <div className={`page ${isLoggedIn ? 'inactive' : 'active'}`}></div>
        <p className="background-text">Hi, I'm Sara</p>
        <p className="text">
      <span className="information-person">Love for design.</span>
      <span className="skills"> CSS/Sass.</span>
      <span className="information-person"> Göteborg, Sweden.</span>
      <span className="skills"> API integrations. </span>
      <span className="project-link" onClick={() => openWebsite('https://sarasoderlindart.netlify.app/')}> Artist.</span>
      <span className="information"> User-friendly experiences. </span>
      <span className="information"> Project Leader intern.</span>
      <span className="skills"> Web3.js.</span>
      <span className="skills"> Javascript.</span>
      <span className="skills"> Metamask.</span>
      <span className="skills"> Github.</span>
      <span className="skills"> Cryptography. </span>
      <span className="project-link" onClick={() => openWebsite('https://github.com/soderlinds/Rankify')}>Rankify.</span>
      <span className="skills"> Responsive design. </span>
      <span className="web3" onClick={login}> Explore web3 here. </span>
      <span className="information-person"> Creativity. </span>
      <span className="skills"> Smart Contracts.</span>
      <span className="skills"> Graphic design.</span>
      <span className="information-person"> Problem-solving. </span>
      <span className="information-person"> Medieinstitutet.</span>
      <span className="skills"> WordPress.</span>
      <span className="skills"> Typescript. </span>
      <span className="link-socials" onClick={() => openWebsite('https://github.com/soderlinds')}> Github.</span>
      <span className="skills"> Ganache.</span>
      <span className="information-person"> Efficiency. </span>
      <span className="skills">React.js. </span>
      <span className="information-person"> Strong work ethic. </span>
      <span className="information">Decentralized applications. </span>
      <span className="skills"> Subgraph.</span>
      <span className="information-person"> Innovations.</span>
      <span className="skills"> Video editing.</span>
      <span className="skills"> GraphQL.</span>
      <span className="information-person"> Blockchain enthusiast.</span>
      <span className="skills"> Code optimization. </span>
      <span className="project-link" onClick={() => openWebsite('https://github.com/soderlinds/DSP')}>SDV Loyalty Token.</span>
      <span className="skills"> HTML.</span>
      <span className="link-socials" onClick={() => openWebsite('https://www.linkedin.com/in/sara-s%C3%B6derlind-482a871ba/')}> LinkedIn.</span>
      <span className="information-person"> Visual thinker.</span>
      <span className="skills"> Agile Methods.</span>
      <span className="skills"> RESTful APIs.</span>
      <span className="skills"> Frontend development.</span>
      <span className="information-person"> Self-motivated. </span>
      <span className="skills">Solidity. </span>
      <span className="skills"> React Native. </span>
      <span className="project-link" onClick={() => openWebsite('https://crowdfundingprototype.netlify.app/')}> Crowdfunding web3.</span>
      <span className="skills"> Figma.</span>
      <span className="skills"> Node.js. </span>
      <span className="information-person"> Multitasker. </span>
      <span className="skills"> Ethers.js. </span>
      <span className="skills"> Typography.</span>
      <span className="skills"> Remix IDE.</span>
      <span className="skills"> Truffle Suite.</span>
      <span className="information"> Frontend developer intern. </span>
      <span className="project-link" onClick={() => openWebsite('https://github.com/soderlinds/habithub')}> Habit Hub.</span>
      <span className="skills"> NFTs.</span>
      <span className="link-socials" onClick={() => (window.location = 'mailto:soderlind.sara@gmail.com')}> Contact.</span>
      </p>
      <div className={`page ${isLoggedIn ? 'active' : 'inactive'}`}>
      {isLoggedIn && <LoggedIn handleLogout={handleLogout} />}
      </div>
      </div>
    </div>
  );
};

export default HomeDeveloper;

