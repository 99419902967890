import React from 'react'
import '../styles/_footer.sass'
import '../styles/_typography.sass'

const Footer = () => {
  return (
    <div className="footer-content">
      <p className="text-footer">© 2024 Sara Söderlind </p>
      
    </div>
  )
}

export default Footer
