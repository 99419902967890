import './App.css';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Work from './components/Work';
import Exhibitions from './components/Exhibitions';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Main from './pages/Main';
import HomeDeveloper from './pages/HomeDeveloper';
import './styles/_general.sass';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <AppContent />
      </div>
    </BrowserRouter>
  );
}

function AppContent() {
  const location = useLocation();

  const hideHeaderMain = location.pathname === '/';
  

  const hideHeaderFooterHomeDeveloper = location.pathname === '/home-developer';

  return (
    <>
      {!hideHeaderMain && !hideHeaderFooterHomeDeveloper && <Header />}
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/home-developer" element={<HomeDeveloper />} />
        <Route path="/home-artist" element={<Home />} />
        <Route path="/work" element={<Work />} />
        <Route path="/about" element={<About />} />
        <Route path="/exhibitions" element={<Exhibitions />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      {!hideHeaderFooterHomeDeveloper && <Footer />}
    </>
  );
}

export default App;
