import React from 'react';
import { usePrivy } from '@privy-io/react-auth'; 
import '../styles/_loggedin.scss';

const LoggedIn = ({ handleLogout }) => {
  const { user } = usePrivy(); 

  return (
    <div className="loggedInWrapper">
      {user && (
        <>
          <div className="headerLoggedIn">And just like that you have an embedded wallet, here it is:</div>
          <span className="userId">{user.id}</span> 
          <p className="moreInfo">More to come!</p>
          <button onClick={handleLogout} className="logout">Logout</button>
        </>
      )}
    </div>
  );
}

export default LoggedIn;
