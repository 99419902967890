import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/_main.sass';

const Main = () => {
  const [typedText, setTypedText] = useState('');
  const roles = ['Blockchain Developer', 'Frontend Developer', 'Fullstack Developer'];

  useEffect(() => {
    let roleIndex = 0;
    let textIndex = 0;
    let isErasing = false;
    let eraseTimer;

    const typeRole = () => {
      if (!isErasing && textIndex <= roles[roleIndex].length) {
        setTypedText(roles[roleIndex].substring(0, textIndex));
        textIndex++;
        eraseTimer = setTimeout(() => {
          typeRole();
        }, 100); 
      } else if (!isErasing && textIndex > roles[roleIndex].length) {
        isErasing = true;
        clearTimeout(eraseTimer);
        setTimeout(() => {
          eraseRole();
        }, 1000); 
      }
    };

    const eraseRole = () => {
      if (isErasing && textIndex >= 0) {
        setTypedText(roles[roleIndex].substring(0, textIndex));
        textIndex--;
        eraseTimer = setTimeout(() => {
          eraseRole();
        }, 50); 
      } else if (isErasing && textIndex < 0) {
        isErasing = false;
        roleIndex++;
        if (roleIndex >= roles.length) {
          roleIndex = 0; 
        }
        setTimeout(() => {
          textIndex = 0;
          typeRole();
        }, 1000); 
      }
    };

    typeRole();

    return () => {
      clearTimeout(eraseTimer);
    };
  }, []);

  useEffect(() => {
    const mainWrapper = document.querySelector('.main-wrapper');
    if (mainWrapper) {
      mainWrapper.classList.add('show');
    }
  }, []);

  return (
    <div className="main-wrapper">
      <div className="header-name">Sara Söderlind</div>
      <div className="role">
        <div className="role-text"><Link className="text-role-developer" to="/home-developer">{typedText}</Link></div>
        <div className="vertical-line"></div>
        <div className="role-text"><Link className="text-role-artist" to="/home-artist">Abstract Artist 🎨</Link></div>
      </div>
    </div>
  );
};

export default Main;



// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import '../styles/_main.sass';

// const Main = () => {
//   const [typedText, setTypedText] = useState('');
//   const roles = ['Blockchain Developer', 'Frontend Developer', 'Fullstack Developer'];

//   useEffect(() => {
//     let roleIndex = 0;
//     let textIndex = 0;
//     let isErasing = false;
//     let eraseTimer;

//     const typeRole = () => {
//       if (!isErasing && textIndex <= roles[roleIndex].length) {
//         setTypedText(roles[roleIndex].substring(0, textIndex));
//         textIndex++;
//         eraseTimer = setTimeout(() => {
//           typeRole();
//         }, 100); 
//       } else if (!isErasing && textIndex > roles[roleIndex].length) {
//         isErasing = true;
//         clearTimeout(eraseTimer);
//         setTimeout(() => {
//           eraseRole();
//         }, 1000); 
//       }
//     };

//     const eraseRole = () => {
//       if (isErasing && textIndex >= 0) {
//         setTypedText(roles[roleIndex].substring(0, textIndex));
//         textIndex--;
//         eraseTimer = setTimeout(() => {
//           eraseRole();
//         }, 50); 
//       } else if (isErasing && textIndex < 0) {
//         isErasing = false;
//         roleIndex++;
//         if (roleIndex < roles.length) {
//           setTimeout(() => {
//             textIndex = 0;
//             typeRole();
//           }, 1000); 
//         }
//       }
//     };

//     typeRole();

//     return () => {
//       clearTimeout(eraseTimer);
//     };
//   }, []);

//   useEffect(() => {
//     const mainWrapper = document.querySelector('.main-wrapper');
//     if (mainWrapper) {
//       mainWrapper.classList.add('show');
//     }
//   }, []);

//   return (
//     <div className="main-wrapper">
//       <div className="header-name">Sara Söderlind</div>
//       <div className="role">
//         <div className="role-text"><Link className="text-role-developer" to="/home-developer">{typedText}</Link></div>
//         <div className="vertical-line"></div>
//         <div className="role-text"><Link className="text-role-artist" to="/home-artist">Abstract Artist 🎨</Link></div>
//       </div>
//     </div>
//   );
// };

// export default Main;